<template>
  <div class="login">
    <div class="main">
      <div class="txt">
        <p>欢迎登录！</p>
        <p>数字农业管理系统</p>
        <p>Welcome to Agriculture Management platform</p>
      </div>
      <div class="form">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="账号登录" name="first">
            <login-form @openWindow="openWindow"></login-form>
          </el-tab-pane>
          <el-tab-pane label="手机号登录" name="second">
            <login-form
              :isPhone="isPhone"
              @openWindow="openWindow"
            ></login-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 小窗口 -->
    <el-dialog
      :visible.sync="mapInfoShow"
      title=" "
      class="big-pop"
      width="727px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="mapInfoClose"
    >
      <div
        class="big-main"
        :style="
          'background: url(' +
          $imgOnlineUrl +
          '/index/popBgimg.png) no-repeat center center;background-size: cover;'
        "
      >
        <div class="main-list">
          <div
            class="items"
            v-for="(item, index) in farmList"
            :key="item.id"
            @click="editFarm(item.id, index)"
          >
            <img
              class="imgs"
              :src="item.photo ? uploadImgs + item.photo : defaultImgs"
            />
            <div class="right-info">
              <div class="title">{{ item.title }}</div>
              <div class="address">
                <img
                  class="icon11-img"
                  :src="$imgOnlineUrl + '/index/icon11.png'"
                />
                <p class="address-txt">
                  {{ item.address }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getFarmListApi, getFarmInfoApi } from "@/request/api";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    loginForm: () => import("./component/loginForm.vue"),
  },
  data() {
    return {
      activeName: "first",
      isPhone: "0",
      total: 20,
      last_page: 1,
      per_page: 10,
      current_page: 1,
      mapInfoShow: false,
      farmName: "",
      farmList: [],
      defaultImgs: require("@/assets/image/configure/farm/icon8.png"),
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      userInfo: (state) => state.user.userInfo,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    localStorage.setItem("userInfoObj", "{}");
    localStorage.setItem("farmInfo", "{}");
    localStorage.setItem("farmId", "");
    localStorage.setItem("darsingToken", "");
    localStorage.setItem("assignManorId", "");
    localStorage.setItem("exp", "");
  },
  methods: {
    ...mapMutations("user", ["SET_FARM_INFO", "SET_FARM_Id", "SET_WEB_MENU"]),
    async getFarmInfoApi2(id) {
      const { code, results } = await getFarmInfoApi({
        manor_id: id,
      });
      if (code !== 0) return;
      let obj = {};
      obj.address = results.address;
      obj.area = results.area;
      obj.title = results.title;
      obj.photo = results.photo;
      obj.position = results.position;
      obj.screen_page = results.screen_page;
      obj.zoom = results.zoom;
      // obj.menu_power = results.menu_power;
      obj.name = results.name;
      obj.title = results.title;
      obj.id = results.id;
      obj.vr_page = results.vr_page;
      this.farmList.push(obj);
      this.$forceUpdate();
    },
    async getFarmInfoApi(id) {
      const { code, results } = await getFarmInfoApi({
        manor_id: id,
      });
      if (code !== 0) return;
      let obj = {};
      obj.address = results.address;
      obj.area = results.area;
      obj.title = results.title;
      obj.photo = results.photo;
      obj.position = results.position;
      obj.screen_page = results.screen_page;
      obj.zoom = results.zoom;
      // obj.menu_power = results.menu_power;
      obj.name = results.name;
      obj.title = results.title;
      obj.id = results.id;
      obj.vr_page = results.vr_page;
      this.SET_FARM_INFO({ farmInfo: obj });
      this.SET_FARM_Id({ farmId: results.id });
      localStorage.setItem("farmInfo", JSON.stringify(obj));

      const web_menu = results.web_menu;
      this.SET_WEB_MENU({ web_menu });
      if (web_menu[0]) {
        localStorage.setItem("web_menu", JSON.stringify(web_menu));
      }
      this.$router.push({
        name: "index",
      });
    },
    openWindow() {
      if (localStorage.getItem("assignManorId") != 0) {
        this.getFarmInfoApi2(localStorage.getItem("assignManorId"));
      } else {
        this.getFarmListApi();
      }
      this.mapInfoShow = true;
    },
    editFarm(id, index) {
      this.farmName = this.farmList[index].title;
      this.mapInfoShow = false;
      this.getFarmInfoApi(id);
    },
    mapInfoClose() {
      this.mapInfoShow = false;
    },
    async getFarmListApi() {
      const { code, results } = await getFarmListApi();
      if (code === 0) {
        this.farmList = results.data;
        this.farmName = this.farmList[0].title;
        this.total = results.count;
        this.per_page = results.ps;
        this.current_page = results.pn;
      }
    },

    handleClick({ index }) {
      this.isPhone = index;
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  background: url(../../assets/image/login/bg.png) center center no-repeat;
  background-size: cover;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 183px;
  position: relative;
  .main {
    width: 100%;
    height: 476px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .txt {
      p {
        width: 100%;
        color: #fff;
        font-size: 44px;
        &:nth-of-type(1) {
          margin-bottom: 35px;
        }
        &:nth-of-type(2) {
          color: #3e90e5;
          font-weight: bold;
          margin-bottom: 26px;
        }
        &:nth-of-type(3) {
          font-size: 18px;
        }
      }
    }
    .form {
      width: 482px;
      height: 476px;
      background: #fff;
      border-radius: 5px;
      padding: 46px 56px;
    }
  }
}
.big-pop {
  /deep/.el-dialog {
    background-color: inherit;
    width: 727px !important;
  }

  /deep/.el-dialog__body {
    padding: 0;
  }

  /deep/.el-dialog__headerbtn {
    font-size: 26px;
    top: 64px;
    right: 50px;
    font-weight: bold;
  }

  .big-main {
    width: 727px;
    height: 526px;
    padding: 65px 55px 10px 55px;
    .main-list {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }

      .items {
        width: 100%;
        height: 120px;
        background-color: rgba(62, 144, 229, 0.25);
        margin-bottom: 26px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;

        &:hover {
          background-color: rgba(62, 144, 229, 0.15);
        }

        .imgs {
          width: 110px;
          height: 77px;
          margin-right: 17px;
        }

        .right-info {
          width: 100%;
          display: flex;
          flex-direction: column;

          .title {
            font-size: 18px;
            margin-bottom: 24px;
            font-weight: bold;
            color: #fff;
          }

          .address {
            display: flex;
            align-items: center;
            width: 100%;

            .icon11-img {
              width: 10px;
              height: 12px;
              margin-right: 6px;
            }

            .address-txt {
              color: #fff;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
